import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const dynamicMasterFormContent = require("./dynamicMasterFormContent.json");
const memoItemsFormContent = require("./memoItemsFormContent.json");
import { SnackbarLoaderContext } from "../../../components/src/context";
import StorageProvider from "framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleContentTypeSwitch: any;
  selectedDocumentId: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formData: any;
  isSubmitted: boolean;
  emptyFieldList: string[];
  memoItemsEmptyFieldList: string[];
  isDialogOpen: boolean;
  alertMessage: string;
  billType: string;
  StatusToastopen: boolean;
  StatusToastMessage: string;
  StatusToastSeverity: "success" | "error" | "warning" | "info";
  memoItemsFormContent: any;
  memoItemsFormDataById: any;
  errorPopup:boolean;
  locationOption:{
    id: number,
    internal_id: string,
    name: string,
    warehouse_code: string,
    inventory_status: string,
    location_code: string
  }[];
  expenseOption:{
    id:number,
    number_and_account:string
  }[];
  firstRendering:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DocumentPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUploadedPDFParseDataAPICallId: string = "";
  updateParseInoiceDataAPICallId: string = "";
  addMemoDataAPICallId: string = "";
  removeMemoItemDataAPICallId: string = "";
  getMemoItemsAPICallId: string = "";
  getMemoItemsFormAPICallId: string = "";
  getLocationId: string = "";
  getExpenseId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      formData: null,
      isSubmitted: false,
      emptyFieldList: [],
      memoItemsEmptyFieldList: [],
      isDialogOpen: false,
      alertMessage: "",
      billType: "",
      StatusToastopen: false,
      StatusToastMessage: "",
      StatusToastSeverity: "success",
      memoItemsFormContent: [],
      memoItemsFormDataById: {
        memo_item: "",
        expense_coa: " ",
        amount_base: "",
        amount_add_non_tax: " ",
        project: "",
        brand: " ",
      },
      errorPopup:false,
      locationOption:[],
      expenseOption:[],
      firstRendering:true
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receivePDFPreview(message);
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType = SnackbarLoaderContext;
  async componentDidMount() {
    super.componentDidMount();
    this.getUploadedPDFParseDataAPICallIdAPICall(this.props.selectedDocumentId);
    this.getMemoItemsAPICall();
    this.getLocationAPICallId();
    this.getExpenseAPICallId();
  }

  receivePDFPreview = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandllerPDFPreview(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandllerPDFPreview = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    this.context.unsetLoader && this.context.unsetLoader();
    switch (apiRequestCallId) {
      case this.getUploadedPDFParseDataAPICallId:
        if (!responseJson.errors) {
          this.handleResponseFormating(responseJson);
          this.getAddMemoItemsFormAPICall();
        }
        break;
      case this.updateParseInoiceDataAPICallId:
        this.handleUpdateParseInoiceDataAPICallId(responseJson);
        break;
      case this.addMemoDataAPICallId:
        if (!responseJson.errors) {
          this.setState({
            StatusToastopen: true,
            StatusToastMessage: "MemoItem Added successfully!",
            StatusToastSeverity: "success",
          });
          this.getUploadedPDFParseDataAPICallIdAPICall(
            this.state.formData?.document_id
          );
        }
        break;
      case this.removeMemoItemDataAPICallId:
        if (responseJson.message === "MemoItem deleted successfully!") {
          this.setState({
            StatusToastopen: true,
            StatusToastMessage: responseJson.message,
            StatusToastSeverity: "success",
          });
          this.getUploadedPDFParseDataAPICallIdAPICall(
            this.state.formData?.document_id
          );
        }
        break;
      case this.getMemoItemsAPICallId:
        if (!responseJson.errors) {
          this.setState({
            memoItemsFormContent: responseJson,
          });
        }
        break;
      case this.getMemoItemsFormAPICallId:
        if (!responseJson.errors) {
          this.setState({
            memoItemsFormDataById: responseJson,
          });
        }
        break;
        case this.getLocationId:
        if (!responseJson.errors) {
          this.setState({
            locationOption: responseJson,
          });
        }
        break;
        case this.getExpenseId:
          this.handleExpenseResponseCall(responseJson);
        break;
      default:
        break;
    }
  };

  handleUpdateParseInoiceDataAPICallId = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data?.attributes?.status === "SUBMITTED") {
        this.setState({
          isSubmitted: true,
        });
      }
      if(this.state.errorPopup){
          this.props.handleContentTypeSwitch("uploadDocument");
      }else{
        this.handleResponseFormating(responseJson);
      }
    } else {
      this.setState({
        isDialogOpen: true,
        alertMessage: responseJson.errors.message,
      });
    }
  };
  handleExpenseResponseCall = (responseJson:any) => {
    if(!responseJson.errors){
      this.setState({
        expenseOption:responseJson
      })
    }
  }

  handleResponseFormating = (responseJson: any) => {
    let commaSeparatedBlNumber: string;
    let newReseponse = { ...responseJson.data.attributes };
    if (newReseponse.bl_no) {
      let bl_no: string[] = newReseponse.bl_no;
      commaSeparatedBlNumber = bl_no.join(",");
      newReseponse = { ...newReseponse, bl_no: commaSeparatedBlNumber };
    }
    this.setState(
      {
        formData: newReseponse,
        billType: responseJson.data.type,
      },
      () => {
        if (responseJson.data.attributes.alert) {
          this.setState({
            isDialogOpen: true,
            alertMessage: responseJson.data.attributes.alert,
          });
        }
        if(this.state.firstRendering){
          this.handleSubmitClick()
        }
        this.setState({firstRendering:false})
      }
    );
  };
 
  getUploadedPDFParseDataAPICallIdAPICall = async (documentId: string) => {
    this.handleSetLoader();
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUploadedPDFParseDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.uploadedPDFParseData}/${documentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateParseInvoiceData = async (documentId: number, filteredData: any) => {
    this.handleSetLoader();
    const header = {
      token: await StorageProvider.get("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateParseInoiceDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.uploadedPDFParseData}/${documentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(filteredData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBackClick = () => {
    this.props.handleContentTypeSwitch("uploadDocument");
  };

  handleFieldChange = (
    fieldName: string,
    value: string | number,
    memoItemIndex?: number
  ) => {
    if (memoItemIndex !== undefined) {
      const memoItems = [...this.state.formData.memo_items];
      memoItems[memoItemIndex] = {
        ...memoItems[memoItemIndex],
        [fieldName]: value,
      };
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          memo_items: memoItems,
        },
      }));
    } else {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [fieldName]: value,
        },
      }));
    }
  };

  checkEmptyValuesForMemoItems = (memoItems: any) => {
    const memoItemResult = [];
    for (let index = 0; index < memoItems.length; index++) {
      for (const item of this.state.memoItemsFormContent) {
        const { name, isDisabled, type } = item;
        if (
          (!isDisabled &&
            (memoItems[index][name] === "" ||
              memoItems[index][name] === null)) ||
          (type === "select" && memoItems[index][name] === "Please Select")
        ) {
          memoItemResult.push(`${item.name}_${index}`);
        }
      }
    }
    return memoItemResult;
  };

  checkAllEmptyValues = () => {
    const result = [];
    let memoItemResult: any = [];
    const { formData } = this.state;
    if (formData.memo_items) {
      memoItemResult = this.checkEmptyValuesForMemoItems(formData.memo_items);
    }

    for (const item of dynamicMasterFormContent) {
      const { name, isDisabled } = item;
      if (!isDisabled && (formData[name] === "" || formData[name] === null)) {
        result.push(item.name);
      }
    }
    this.setState({
      emptyFieldList: result,
      memoItemsEmptyFieldList: memoItemResult,
    });
    return result.length + memoItemResult.length;
  };

  handleSubmitClick = (isSubmit?: boolean) => {
    const { formData } = this.state;
    const emptyFieldListLength = this.checkAllEmptyValues();
    if (emptyFieldListLength > 0) {
      this.setState({
        isDialogOpen: true,
        alertMessage: configJSON.emptyFiledsErrorMessage,
      });
      return;
    }
    const filteredKeys = Object.keys(formData).filter((key) =>
      dynamicMasterFormContent.some((item: any) => item.name === key)
    );

    let filteredData = filteredKeys.reduce((filtered: any, key) => {
      filtered[key] = formData[key];
      return filtered;
    }, {});

    if (formData.memo_items) {
      filteredData = {
        ...filteredData,
        memo_items_attributes: [...formData.memo_items],
      };
    }

    if (isSubmit) {
      filteredData = { ...filteredData, status: "SUBMITTED" };
      this.setState({
        errorPopup:isSubmit
      })
    } else {
      filteredData = { ...filteredData };
      this.setState({
        errorPopup:false
      })
    }
    this.updateParseInvoiceData(formData.document_id, filteredData);
  };

  handleAddMemo = () => {
    const { formData, memoItemsFormDataById } = this.state;
    const newMemoItem: any = { ...memoItemsFormDataById };
    const existingMemoItems = formData.memo_items || [];
    this.setState({
      formData: {
        ...formData,
        memo_items: [...existingMemoItems, newMemoItem],
      },
    });
  };

  handleCancelMemo = (index: number): void => {
    const { formData } = this.state;

    const updatedMemoItems = formData.memo_items.filter(
      (item: any, i: number) => i !== index
    );

    this.setState({
      formData: {
        ...formData,
        memo_items: updatedMemoItems,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleSetLoader = () => {
    this.context.setLoader && this.context.setLoader();
  };

  handleSetSnackbar = (message: string) => {
    this.context.setSnackbar && this.context.setSnackbar(message);
  };

  addMemo = async (documentId?: any, data?: any) => {
    this.handleSetLoader();
    const header = {
      token: await StorageProvider.get("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addMemoDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentopener2/documents/${documentId}/add_memo_item`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeMemoItemsById = async (memoItemId?: any): Promise<void> => {
    this.handleSetLoader();
    const header = {
      token: await StorageProvider.get("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemoItemDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentopener2/documents/${this.state.formData?.document_id}/delete_memo_item/${memoItemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSubmitNewMemoData = (
    index: number | undefined,
    memoName?: string
  ): void => {
    const { formData } = this.state;
    if (
      index !== undefined &&
      index >= 0 &&
      index < formData.memo_items.length
    ) {
      const { id, ...memo_item } = formData.memo_items[index];
      const emptyValues = this.checkEmptyValuesForMemoItems([memo_item]);
      if (emptyValues.length > 0) {
        this.setState({
          StatusToastopen: true,
          StatusToastMessage: `Please ensure all ${memoName} fields are filled.`,
          StatusToastSeverity: "warning",
        });
        return;
      } else {
        const data = {
          memo_item,
        };

        this.addMemo(formData.document_id, data);
      }
    }
  };

  handleStatusToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ StatusToastopen: false });
  };

  getMemoItemsAPICall = async () => {
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMemoItemsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cffetchdetailsfrominvoicetemplate/memo_items/get_memo`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAddMemoItemsFormAPICall = async () => {
    const { formData } = this.state;
    const formDataId = new FormData();
    formDataId.append("category_id", `${formData?.category_id}`);
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMemoItemsFormAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cffetchdetailsfrominvoicetemplate/memo_items/create_memo_item`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getLocationAPICallId = async() =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationId = requestMessage.messageId;

    const header = {
      token: await StorageProvider.get("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getExpenseAPICallId = async() =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseId = requestMessage.messageId;

    const header = {
      token: await StorageProvider.get("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
